<script setup>

import Card from "@/Components/Card.vue";
import CardGame from "@/Components/CardGame.vue";
import {Link} from '@inertiajs/vue3'
import {computed} from "vue";

const props = defineProps({
    title: String,
    games: Array,
    url: String,
    isMany: {
        type: Boolean,
        default: false,
    },
    category: String | undefined,
})

const gameCurrencyImages = {
    "brawl stars": "./assets/img/game-currency/brawl-stars-gems.webp",
    "genshin impact": "./assets/img/game-currency/genshin-impact-genesis-crystals.webp",
    "minecraft": "./assets/img/game-currency/minecraft-minecoins.webp",
    "roblox": "./assets/img/game-currency/robux.webp",
    "world of tanks": "./assets/img/game-currency/world-of-tanks-gold.webp",
    "fortnite": "./assets/img/game-currency/fortnite-v-bucks.webp",
    "dragonheir: silent gods": "./assets/img/game-currency/dragon-heir-silent-gods-dragon-crystalsя.webp",
    "valorant": "./assets/img/game-currency/valorant-points.webp"
}

const isCurrencies = computed(() =>  props.category === 'game-currency')

</script>

<template>
    <h5 class="text-base lg:text-xl font-medium dark:text-slate-300 leading-120 py-3 px-1 font-['Unbounded']">
        {{ title }}
    </h5>
    <card
        :class="isMany ? 'lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 lg:gap-2' : 'lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 lg:gap-2.5'"
        class="grid grid-cols-1  lg:px-2.5 pb-2.5 lg:py-2.5 font-medium dark:dg-deep-blue">

        <template v-for="product in props.games">
            <CardGame
                :class="[isMany ? 'lg:[&:nth-child(-n+9)]:flex xl:[&:nth-child(-n+12)]:flex' :
                    'lg:[&:nth-child(-n+6)]:flex 2xl:[&:nth-child(-n+9)]:flex', {'[&:nth-child(-n+12)]:flex hidden' : isCurrencies}]"
                class="[&:nth-child(-n+3)]:flex hidden"
                :is-currency="isCurrencies"
                :product="{...product, image_url: gameCurrencyImages[product.title.toLowerCase()] && isCurrencies ? gameCurrencyImages[product.title.toLowerCase()] : product.image_url}" :mini-img="isMany"/>
        </template>
        <div v-if="!isCurrencies" class="col-span-full px-9 pt-1.5 font-normal dark:dg-dark-blue">
            <Link :href="url"
                  method="get"
                  class="border grow flex items-center justify-center border-slate-500 dark:border-slate-400 dark:text-slate-200 dark:hover:bg-deep-blue rounded-lg w-full h-10 transition-colors hover:bg-slate-200 text-sm font-medium">
                <span>{{ $t('Show all') }}</span>
            </Link>

        </div>
    </card>

</template>

<style scoped>

</style>
